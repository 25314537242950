<template>
  <b10-list-item
    :item="formattedItem"
  >
    <v-list-item-avatar>
      <div
        class="text-xs-center"
      >
        <v-avatar
          :color="formattedItem.avatarColor"
        >
          <span class="white--text font-weight-bold subtitle-1">{{ formattedItem.avatar }}</span>
        </v-avatar>
      </div>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title
        v-html="formattedItem.title"
      />
      <v-list-item-subtitle
        v-html="formattedItem.subtitle"
      />
    </v-list-item-content>
  </b10-list-item>
</template>

<script>
import _ from '@/utils/lodash'

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    formattedItem () {
      const item = _.cloneDeep(this.item)
      item.title = item.plantilla_articulo.descripcion
      item.subtitle = item.grupos.join(', ')
      item.avatar = item.grupos.length
      item.avatarColor = 'info'
      return item
    }
  }
}
</script>
